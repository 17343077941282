const DummyFunction = () => {
  return null;
};

export default DummyFunction;
const footerButtonStyleText = {
  display: 'flex',
  height: '48px',
  padding: '0px 24px',
  justifyContent: 'center',
  alignItems: 'center',
  flex: '1 0 0',
};

const footerButtonStyleContained = {
  display: 'flex',
  height: '48px',
  padding: '0px 24px',
  justifyContent: 'center',
  alignItems: 'center',
  flex: '1 0 0',
};

const materialUIButtonStyle = {
  width: '100%',
  borderRadius: '100px',
};

export { footerButtonStyleText, footerButtonStyleContained, materialUIButtonStyle };
